/* Home.css */

.home {
  /* padding: 2rem; */
  /* background:linear-gradient(white, rgba(163, 255, 190, 0.2)); */
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

@-webkit-keyframes slide {
  0% {
    left: 0%;
  }
  14.29% {
    left: 0%;
  }
  16.67% {
    left: -100%;
  }
  30.96% {
    left: -100%;
  }
  33.33% {
    left: -200%;
  }
  47.62% {
    left: -200%;
  }
  50% {
    left: -300%;
  }
  64.29% {
    left: -300%;
  }
  66.67% {
    left: -400%;
  }
  80.96% {
    left: -400%;
  }
  83.33% {
    left: -500%;
  }
  97.62% {
    left: -500%;
  }
  100% {
    left: -600%;
  }
}

@keyframes slide {
  0% {
    left: 0%;
  }
  14.29% {
    left: 0%;
  }
  16.67% {
    left: -100%;
  }
  30.96% {
    left: -100%;
  }
  33.33% {
    left: -200%;
  }
  47.62% {
    left: -200%;
  }
  50% {
    left: -300%;
  }
  64.29% {
    left: -300%;
  }
  66.67% {
    left: -400%;
  }
  80.96% {
    left: -400%;
  }
  83.33% {
    left: -500%;
  }
  97.62% {
    left: -500%;
  }
  100% {
    left: -600%;
  }
}

.slider {
  font-family: Verdana, Geneva, sans-serif;
  overflow: hidden;
  width: 100%;
}
.slider__container {
  width: 700%; /* 7 images * 100% */
  position: relative;
  display: flex;
  -webkit-animation: 35s slide infinite;
  animation: 35s slide infinite;
  /* font-size: 0; */
}

.slider__container img {
  max-height: 100vh;
  position: relative;
  /* width: 100%; */
  display: block; /* Remove extra space below images */
}
.slider__container figure {
  display: inline-block;
  width: 14.29%; /* 100% / 7 images */
}
/* .slider__container figcaption {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  font-size: 32px;
  color: #fff;
  padding: 10px 16px;
  text-shadow: 2px 2px 6px #222;
  width: 100%;
} */

.slider__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 100%;
  z-index: 1; /* Ensures content is above the images */
}

.slider__content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  padding: 0.7%;
  backdrop-filter: brightness(55%);
}

.slider__content p {
  font-size: 1.5rem;
  padding: 0.7%;
  border-radius: 10px;
  backdrop-filter: brightness(55%);
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .slider__container {
    height: 90vh;
  }
  .slider__content {
    top: 75%;
  }
}

/* Mobile View Adjustments */
@media screen and (max-width: 768px) {
  /* Adjust the max-width value as needed */
  .slider {
    height: fit-content; /* Adjust height for mobile view */
  }
  .form-section {
    display: contents !important;
  }
  .home-form {
    background: none !important;
    flex-direction: column;
    font-size: large;
    line-height: 33px;
  }
  .home-form label {
    color: black !important;
  }
  .submit-button-blue {
    background-color: rgb(51, 145, 203, 0.9) !important;
  }
  .slider__container img {
    width: 100%;
  }
  .products h2 {
    padding: 20px 35px !important;
    margin-top: 50px;
  }
}

.hero {
  text-align: center;
  /* padding: 4rem 1rem; */
  background-color: transparent;
  /* margin-bottom: 2rem; */
  background: url("../../assets/leaf4.jpeg") no-repeat;
}

.hero-inner {
  padding-top: 17%;
  padding-bottom: 17.4%;
}

.hero h1 {
  font-size: 2.9rem;
  color: white;
}

.hero p {
  font-size: 1.7rem;
  color: #dddddd;
}

.services {
  /* margin-bottom: 2rem; */
  background: url("../../assets/services.jpg") repeat fixed;
  background-size: cover;
  padding: 5%;
}

.products {
  padding: 5%;
  display: block;
  justify-content: center;
  /* margin-bottom: 2rem; */
  /* border-top: 2px solid rgb(0, 30, 128);
  border-bottom: 2px solid rgb(0, 30, 128); */
  /* background-color: rgb(133, 20, 75, 0.1); */
  /* background:linear-gradient(white, rgba(163, 255, 190, 0.4)); */
}

.products h2 {
  font-size: 2rem;
  color: white;
  max-width: fit-content;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 3%;
  padding-right: 3%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  background-color: rgb(51, 145, 203, 0.9);
  border-radius: 10px;
}
.services h2 {
  font-size: 2rem;
  color: white;
  margin-bottom: 1rem;
}

.product-list,
.service-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.product-item,
.service-item {
  /* background-color: rgb(221, 221, 221, 0.6); */
  /* background-color: black; */
  /* border: 3px solid black; */
  /* opacity: 0.6; */
  padding: 1rem;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
}

.product-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}
.service-item h3 {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 0.5rem;
}

.product-item p {
  font-size: 1rem;
  color: #666;
}
.service-item p {
  font-size: 1rem;
  color: white;
}

.soon-span {
  color: rgb(255, 192, 181);
  font-size: 14px;
  vertical-align: middle;
}

.form-section {
  display: flex;
  /* float: left; */
  /* flex: 1; */
  /* justify-content: space-evenly; */
  /* padding-top: 20px; */
  /* align-items: center; */
  /* vertical-align: middle; */
}

.home-form {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: auto; */
  flex: 1;
  /* max-width: fit-content; */
  /* text-align: center; */
  /* align-items: center; */
  /* margin-left: auto;
  margin-right: auto; */
  /* margin-top: 20px; */
  /* background-color: rgba(0, 0, 0, 0.61); */
  background: url("../../assets/services.jpg") fixed;
  z-index: 2;
  /* background: url(../../assets/plant.mp4); */
  /* background-color: black; */
  vertical-align: middle;
}
.home-form label {
  color: white;
  line-height: 30px;
}

.combo-form {
  padding: 2%;
  float: left;
  font-size: larger;
  vertical-align: middle;
}

.video-title {
  color: white;
  font-size: 40px;
  padding: 20px;
  background-color: rgb(51, 145, 203, 0.9);
}

video {
  /* flex: 1; */
  /* border-radius: 10px;  */
  /* width: 100%; */
  height: 70vh;
}

.error {
  color: red; /* Change to your desired color */
  font-size: 14px; /* Adjust the font size as needed */
  margin-top: 5px; /* Add some space above the error text */
}

.submit-button-blue {
  background-color: rgb(51, 145, 203, 0.9);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
}

.submit-button-blue:hover {
  background-color: rgb(133, 20, 75);
}

.input-box {
  padding: 11px;
  border-radius: 5px;
  vertical-align: middle;
}

.welcome-section {
  font-size: x-large;
  line-height: 50px;
  margin-top: 40px;
  margin-bottom: 40px;
}
