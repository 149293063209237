.location {
  padding: 2rem;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.location-info {
  margin-bottom: 2rem;
  background-color: rgb(51, 145, 203, 0.1);
  padding: 20px;
  border-radius: 10px;
}

.location-info h1 {
  font-size: 2.5rem;
  color: var(--title-color);
}

.location-info p {
  font-size: 1.2rem;
  color: var(--text-color);
  line-height: 1.6;
}

.location-map h2 {
  font-size: 2rem;
  color: var(--title-color);
  margin-bottom: 1rem;
}

.map-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;
}

.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 10px;
}

.location-icon {
  height: 45px;
  width: 45px;
  vertical-align: middle;
}

@media (max-width: 1024px) {
  .location-icon {
    height: 35px;
    width: 35px;
  }
}
